(function () {

    'use strict';

    class FilesSelectDialogCtrl {
        constructor($mdDialog, $translate, moment) {
            this.dialog = $mdDialog;
            this.$translate = $translate;
            this.moment = moment;

        }

        static get $inject() {
            return ["$mdDialog", "$translate", "moment"];
        }

        $onInit() {
            this.filesList = this.filesList ? this.filesList : [];
            this.Selectedfile = null;
            console.log(this.filesList)
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.dialog.hide(this.Selectedfile);
        }
        formatFileName(file){
            return this.moment(file, 'YYYYMMDDHHmmss').format('DD-MM-YYYY HH:mm:ss');
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        focusOnOpen: false,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        controller: FilesSelectDialogCtrl,
        template: require("../views/files-select-dialog.tpl.html"),
    };

})();
