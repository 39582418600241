/**
 * Created by Hp on 25/09/2017.
 */

(function () {

    'use strict';

    module.exports = CatalogCtrl;

    CatalogCtrl.$inject = ["catalogService", "$mdDialog"];

    function CatalogCtrl(catalogService, $mdDialog) {
        var vm = this;

        vm.$onInit = init;
        vm.addItem = addItem;
        vm.viewCatalog = viewCatalog;
        vm.submit = submit;
        vm.checkUnique = checkUnique;
        vm.loadProcedures = loadProcedures;
        vm.removeProcedure = removeProcedure;

        function init() {
            vm.catalog = null;
            vm.is_valid_name = true;
            vm.validation = {valid: true, invalid: false};

            vm.promise = catalogService.getCatalogs()
                .then(success);

            function success(data) {
                vm.catalogs = data;

                vm.catalog = data.length > 0 ? _.chain(vm.catalogs).head().cloneDeep().value() : {procedures: []};
            }
        }

        function addItem(obj, ev) {
            vm.catalog = {procedures: []};
        }

        function viewCatalog(item) {
            vm.catalog = _.cloneDeep(item);
            vm.validation = {valid: true, invalid: false};
        }

        function submit() {
            catalogService.saveCatalog(vm.catalog)
                .then(success);

            function success(data) {
                vm.catalog = data;
                if (_.isNil(_.find(vm.catalogs, {name: vm.catalog.name}))) vm.catalogs.push(vm.catalog);
            }
        }

        function loadProcedures(data) {
            _.forEach(data, function (procedure) {
                if (_.isNil(_.find(vm.catalog.procedures, {code: procedure.code}))) {
                    vm.catalog.procedures.push(procedure);
                }
            });
        }

        function removeProcedure(procedure, ev) {
            _.remove(vm.catalog.procedures, {code: procedure.code});
        }

        function checkUnique() {
            if (!_.has(vm.catalog, 'id')) {
                if (!_.isNil(_.find(vm.catalogs, {name: vm.catalog.name}))) vm.is_valid_name = false;
                else vm.is_valid_name = true;
            }
            else {
                if (!_.isNil(_.find(vm.catalogs, function (item) {
                        return item.name == vm.catalog.name && item.id != vm.catalog.id;
                    }))) vm.is_valid_name = false;
                else vm.is_valid_name = true;
            }
            vm.validation = {valid: vm.is_valid_name, invalid: !vm.is_valid_name};
        }


    }

})();