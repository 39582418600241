/**
 * Created by BETALOS on 14/07/2016.
 */
(function () {

    'use strict';

    const angular = require('angular');

    const biologyService = require('./services/biologyService');
    const catalogService = require('./services/catalogService');
    const organizationService = require('./services/organizationService');
    const maintenanceService = require('./services/maintenanceService');
    const CloudPlatformsService = require('./services/cloudPlatformsService');
    const operatorService = require('./services/operatorService');


    const ParamsCtrl = require('./controllers/ParamsCtrl');

    const GeneralConfigCtrl = require('./controllers/GeneralConfigCtrl');
    const PrintingCtrl = require('./controllers/PrintingCtrl');
    const CareSheetPrintingCtrl = require('./controllers/CareSheetPrintingCtrl');
    const CSPrintingFormCtrl = require('./controllers/CSPrintingFormCtrl');

    const AgendaConfigCtrl = require('./controllers/AgendaConfigCtrl');
    const PatientFileSetupCtrl = require('./controllers/PatientFileSetupCtrl');
    const ListSetupContainerCtrl = require('./controllers/ListSetupContainerCtrl');
    const LocationCtrl = require('./controllers/LocationCtrl');

    const EstablishmentCtrl = require('./controllers/EstablishmentCtrl');

    const ProfileSetupCtrl = require('./controllers/ProfileSetupCtrl');
    const ProfileSetupFormCtrl = require('./controllers/ProfileSetupFormCtrl');

    const SpecialitiesCtrl = require('./controllers/SpecialitiesCtrl');
    const AccountsSetupCtrl = require('./controllers/AccountsSetupCtrl');
    const ContactSetupCtrl = require('./controllers/ContactSetupCtrl');

    const DictionarySetupCtrl = require('./controllers/DictionarySetupCtrl');
    const ChoiceListSetupCtrl = require('./controllers/ChoiceListSetupCtrl');
    const ProcedureSetupCtrl = require('./controllers/ProcedureSetupCtrl');
    const MedicineSetupCtrl = require('./controllers/MedicineSetupCtrl');
    const DentalSetupCtrl = require('./controllers/DentalSetupCtrl');
    const BlockSetupCtrl = require('./controllers/BlockSetupCtrl');
    const BlockSetupFormCtrl = require('./controllers/BlockSetupFormCtrl');
    const SurveySetupCtrl = require('./controllers/SurveySetupCtrl');
    const SurveySetupFormCtrl = require('./controllers/SurveySetupFormCtrl');
    const ConsultationSetupCtrl = require('./controllers/ConsultationSetupCtrl');
    const ObservationSetupCtrl = require('./controllers/ObservationSetupCtrl');
    const ConsultationSetupFormCtrl = require('./controllers/ConsultationSetupFormCtrl');
    const CurrentPatientCtrl = require('./controllers/CurrentPatientCtrl');
    const OximetryConfigCtrl = require('./controllers/OximetryConfigCtrl');
    const MeasuresSetupCtrl = require('./controllers/MeasuresSetupCtrl');
    const ExamSetupCtrl = require('./controllers/ExamSetupCtrl');
    const ExamModelFormSetupCtrl = require('./controllers/ExamModelFormSetupCtrl');
    const BiologySetupCtrl = require('./controllers/BiologySetupCtrl');
    const SequenceCtrl = require('./controllers/SequenceCtrl');
    const CertificatesCategorySetupCtrl = require('./controllers/CertificatesCategorySetupCtrl');

    const DevicesCtrl = require('./controllers/DevicesCtrl');
    const DeviceDriversCtrl = require('./controllers/DeviceDriversCtrl');
    const CatalogCtrl = require('./controllers/CatalogCtrl');
    const OrganizationCtrl = require('./controllers/OrganizationCtrl');
    const OperatorSetupCtrl = require('./controllers/OperatorSetupCtrl');
    const PathologiesSetupCtrl = require('./controllers/PathologiesSetupCtrl');

    const mnBiologyParametersTable = require('./components/biology-parameters-table');
    const mnBiologyModelsTable = require('./components/biology-models-table');
    const mnStockConfigTable = require('./components/stock-config-table');

    const mnBiologyModelRow = require('./directives/biology-model-row');

    const DcmConfigCtrl = require('./controllers/DcmConfigCtrl');

    const AppBackupCtrl = require('./controllers/AppBackupCtrl');
    const PatientFusionCtrl = require('./controllers/PatientFusionCtrl');
    const EmailSMSSetupCtrl = require('./controllers/EmailSMSSetupCtrl');
    const EmailSMSUserSetupCtrl = require('./controllers/EmailSMSUserSetupCtrl');
    const MobileAppCtrl = require('./controllers/MobileAppCtrl');
    const VaccinationCalendarSetupCtrl = require('./controllers/VaccinationCalendarSetupCtrl');
    const PregnancyCalendarSetupCtrl = require('./controllers/PregnancyCalendarSetupCtrl');

    const TabletSurveyCtrl = require('./controllers/TabletSurveyCtrl');

    const sequenceService = require('./services/sequenceService');

    const HospitalizationSetupCtrl = require('./controllers/HospitalizationSetupCtrl');

    const MedicalCareSetupCtrl = require('./controllers/MedicalCareSetupCtrl')
    const CloudPlatformsCtrl = require('./controllers/CloudPlatformsCtrl');
    const FormsTemplatesSetupCtrl = require('./controllers/FormsTemplatesSetupCtrl');

    angular
        .module("medinet")

        .service('biologyService', biologyService)
        .service('catalogService', catalogService)
        .service('organizationService', organizationService)
        .service('maintenanceService', maintenanceService)
        .service('sequenceService', sequenceService)
        .service('CloudPlatformsService', CloudPlatformsService)
        .service('operatorService', operatorService)

        .controller('ParamsCtrl', ParamsCtrl)

        .controller('GeneralConfigCtrl', GeneralConfigCtrl)
        .controller('PrintingCtrl', PrintingCtrl)
        .controller('CareSheetPrintingCtrl', CareSheetPrintingCtrl)
        .controller('CSPrintingFormCtrl', CSPrintingFormCtrl)

        .controller('AgendaConfigCtrl', AgendaConfigCtrl)
        .controller('PatientFileSetupCtrl', PatientFileSetupCtrl)
        .controller('ListSetupContainerCtrl', ListSetupContainerCtrl)
        .controller('LocationCtrl', LocationCtrl)

        .controller('EstablishmentCtrl', EstablishmentCtrl)

        .controller('ProfileSetupCtrl', ProfileSetupCtrl)
        .controller('ProfileSetupFormCtrl', ProfileSetupFormCtrl)

        .controller('SpecialitiesCtrl', SpecialitiesCtrl)
        .controller('AccountsSetupCtrl', AccountsSetupCtrl)
        .controller('ContactSetupCtrl', ContactSetupCtrl)

        .controller('DictionarySetupCtrl', DictionarySetupCtrl)
        .controller('ChoiceListSetupCtrl', ChoiceListSetupCtrl)
        .controller('ProcedureSetupCtrl', ProcedureSetupCtrl)
        .controller('MedicineSetupCtrl', MedicineSetupCtrl)
        .controller('DentalSetupCtrl', DentalSetupCtrl)
        .controller('BlockSetupCtrl', BlockSetupCtrl)
        .controller('BlockSetupFormCtrl', BlockSetupFormCtrl)
        .controller('SurveySetupCtrl', SurveySetupCtrl)
        .controller('SurveySetupFormCtrl', SurveySetupFormCtrl)
        .controller('ConsultationSetupCtrl', ConsultationSetupCtrl)
        .controller('ObservationSetupCtrl', ObservationSetupCtrl)
        .controller('ConsultationSetupFormCtrl', ConsultationSetupFormCtrl)
        .controller('CurrentPatientCtrl', CurrentPatientCtrl)
        .controller('OximetryConfigCtrl', OximetryConfigCtrl)
        .controller('MeasuresSetupCtrl', MeasuresSetupCtrl)
        .controller('ExamSetupCtrl', ExamSetupCtrl)
        .controller('ExamModelFormSetupCtrl', ExamModelFormSetupCtrl)
        .controller('BiologySetupCtrl', BiologySetupCtrl)
        .controller('SequenceCtrl', SequenceCtrl)
        .controller('CertificatesCategorySetupCtrl', CertificatesCategorySetupCtrl)
        .controller('PathologiesSetupCtrl', PathologiesSetupCtrl)

        .controller('AppBackupCtrl', AppBackupCtrl)
        .controller('PatientFusionCtrl', PatientFusionCtrl)
        .controller('VaccinationCalendarSetupCtrl', VaccinationCalendarSetupCtrl)
        .controller('PregnancyCalendarSetupCtrl', PregnancyCalendarSetupCtrl)
        .controller('EmailSMSSetupCtrl', EmailSMSSetupCtrl)
        .controller('EmailSMSUserSetupCtrl', EmailSMSUserSetupCtrl)

        .controller('MobileAppCtrl', MobileAppCtrl)

        .controller('TabletSurveyCtrl', TabletSurveyCtrl)

        .controller('DevicesCtrl', DevicesCtrl)
        .controller('DeviceDriversCtrl', DeviceDriversCtrl)
        .controller('CatalogCtrl', CatalogCtrl)

        .controller('DcmConfigCtrl', DcmConfigCtrl)
        .controller('OrganizationCtrl', OrganizationCtrl)

        .controller('HospitalizationSetupCtrl', HospitalizationSetupCtrl)

        .controller('MedicalCareSetupCtrl', MedicalCareSetupCtrl)
        .controller('CloudPlatformsCtrl', CloudPlatformsCtrl)
        .controller('OperatorSetupCtrl', OperatorSetupCtrl)
        .controller('FormsTemplatesSetupCtrl', FormsTemplatesSetupCtrl)


        .component('mnBiologyParametersTable', mnBiologyParametersTable)
        .component('mnBiologyModelsTable', mnBiologyModelsTable)
        .component('mnStockConfigTable', mnStockConfigTable)


        .directive('mnBiologyModelRow', mnBiologyModelRow);

})();
