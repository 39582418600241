/**
 * Created by BETALOS on 12/10/2016.
 */
(function () {

    'use strict';

    module.exports = catalogService;

    catalogService.$inject = ['$q', '$http'];

    function catalogService($q, $http) {
        let self = this;

        self.saveCatalog = saveCatalog;
        self.getCatalogs = getCatalogs;

        function saveCatalog(catalog) {
            let deferred = $q.defer();

            let url = "/api/procedure-catalog/";

            if (!_.isUndefined(catalog.id)) url += catalog.id + '/';

            $http.post(url, catalog)
                .then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise
        }

        function getCatalogs() {
            let deferred = $q.defer();
            let url = "/api/procedure-catalog/";

            $http.get(url)
                .then(success, error);

            function success(response) {
                deferred.resolve(response.data);
            }

            function error(data) {
                deferred.reject(data);
            }

            return deferred.promise
        }


    }

})();